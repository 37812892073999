@import 'Variables';
.results {
  .result-link {
    float: right;
    background-color: green;
    color: #000000;
  }
  .list-grp {
    text-align: left;
    line-height: 1.5;
    padding: 10px;
    background: #ccc;
  }
}

.result-total {
  display: flex;
  justify-content: space-between;
}

// Product item styles
.product-item {
  margin-bottom: 18px;
  .item-select {
    position: absolute;
    left: 1.25rem;
    top: 1.25rem;
    cursor: pointer;
    width: 26px;
    height: 26px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: {
      radius: 25px;
      color: gray;
      width: 2px;
      style: solid;
    }
    &.selected {
      color: #5d9732;
      border-color: #5d9732;
    }
  }
  .item-img {
    max-width: 480px;
    margin: 0 auto;
    margin-top: 60px;
  }
  .pill-img {
    width: 63px;
    margin: 25px 0 0 25px;
  }
  .card-body {
    position: relative;
    .item-price {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0.1px;
      line-height: 15.29px;
    }
  }
  .item-link {
    font-size: 14px;
    color: #000000;
    letter-spacing: 0.1px;
    line-height: 15.29px;
  }
  .item-tag {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 14px;
    > span {
      display: block;
      border-radius: 5px;
      background: rgb(102, 101, 101);
      color: white;
      padding: 3px;
      margin-bottom: 10px;
    }
  }
  .product-info-switch {
    position: absolute;
    right: 24px;
    top: 24px;
  }
}

.results-banner {
  text-align: center;
  padding: 20px;
}

/*recommendations*/

.recommendations {
  font-family: 'Shaklee-Verlag-Book', sans-serif;
  text-align: center;
  .recommendations-message-wrap {
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #eef3ea;
    .recommendations-message {
      font-family: "Gilmer Regular", "Montserrat", "Open Sans", Helvetica, Arial, sans-serif;
      font-weight: 500;
      color: #2e2e2e;
      padding: 10px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1200px;
      margin: 0 auto;

      &-left {
        font-size: 20px;
        line-height: 20px;
        img {
          margin-right: 10px;
        }
      }

      &-right {
        display: block;
        padding: 30px 15px;
        .close {
          cursor: pointer;
          span {
            font-size: 0;
            background: url(https://images.shaklee.com/meology/icons/mobile/close_button.png) no-repeat;
            width: 23px;
            height: 23px;
            display: block;
          }
        }
      }
    }
  }

  .label-separate {
    font-size: 25px;
  }
  .btn-unselected {
    color: $grey;
  }
  .btn-selected {
    color: $black;
  }
  .label-separate {
    padding: 10px;
  }
  .name {
    text-align: center;
    font-weight: bold;
  }
  .dosage {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
  }
  .hint {
    font-size: 16px;
  }
  .pill-supply {
    text-transform: uppercase;
    font-weight: bold;
    margin: 30px 0;
  }
  .whats-included {
    text-transform: uppercase;
    font-size: 12px;
    margin: 15px -15px;
    padding: 10px;
  }
  .code {
    font-size: 18px;
    font-weight: 400;
  }
  .desc {
    text-align: left;
    font-size: 14px;
  }
  .goal-tag {
    width: 100%;
    margin: 25px 0;
    .title {
      margin-bottom: 20px;
    }
    .goal {
      font-size: 12px;
      padding-right: 10px;
      //replace this with image
      &:before {
        content: ' ';
        border: solid 1px #000000;
        border-radius: 15px;
        height: 30px;
        width: 30px;
        display: inline-block;
        background: #aaaaaa;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
  .pill-quantity {
    font-size: 12px;
  }
  .sub-total {
    margin: 10px -15px;
    padding: 15px;
  }
  .btn-remove {
    color: #000;
    border-color: #000000;
    font-size: 14px;
  }
  .other-recommendations {
    border-top: #e6acac solid 1px;
    margin: 0 -15px;
    padding: 35px 25px 0;
  }
  .recommendations-banner {
    padding: 0 25px;
    .row {
      justify-content: center;
    }
  }
  .section-title {
    font-family: 'Gilmer-Bold', sans-serif;
    font-size: 20px;
    color: #000000;
    letter-spacing: 0.3px;
    line-height: 26px;
    text-align: left;
    padding: 32px 0 12px 10%;
    background-color: $reco_content_bg;
    &-description {
      font-family: 'Shaklee-Verlag-book', sans-serif;
      font-size: 18px;
    }
  }

  &.is-preview-postnatal {
    min-height: calc(100vh - 120px);
    background-color: #EFEDDE;
  }
}
/* step recommendation */
.recommendations-step-provelt {
  .recommendations-step-provelt-container {
	padding: 20px 10%;
	&:nth-child(1) {
      background-color: #abc8b9;
	}
	&:nth-child(2) {
      background-color: #7b9c91;
	}
	.recommendations-step-provelt-info {
	  border-radius: 4px;
	  padding: 30px;
	  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.15);
	  background-color: #fff;
	  .product-info {
		display: flex;
		&-left {
		  width: 50%;
		  text-align: left;
          &-picture {
            max-height: 350px;
          }
		}
		&-right {
          &-step {
            width: 127px;
            height: 35px;
            border-radius: 70px;
            background-color: #7b9c91;
            line-height: 35px;
            text-align: center;
            font-family: 'Gilmer-Bold';
            font-size: 14px;
            color: $white !important;
            margin-bottom: 20px;
          }
		  text-align: left;
		  width: 30%;
		  position: relative;
		  display: flex;
		  flex-direction: column;
		  justify-content: center;
		  .prove-it-list-title {
			font-family: 'Gilmer-Regular', sans-serif;
			font-size: 24px;
		  }
          .prove-it-list-content {
            margin-bottom: 0px;
            padding: 0;
          }
		}
	  }
	  .product-footer {
		display: flex;
		&-left {
		  .learn-more {
			text-decoration: underline !important;
            font-family: 'Shaklee-Verlag-Bold', sans-serif;
			font-size: 16px;
			color: #000000;
			letter-spacing: 0.3px;
			line-height: 21px;
			padding: 0px;
		  }
		}
	  }
	}
  }
}
.recommendations-step-banner {
  border-radius: 4px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: $reco_content_bg;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 25px 0;
  color: #173e35;
  &-title {
	font-family: 'SchnyderS-Demi';
	font-size: 24px;
	line-height: 1.17;
	letter-spacing: 1.52px;
  }
  &-title.hidden {
	visibility: hidden;
  }
  &-container {
    margin-left: 60px;
	display: flex;
	flex-direction: column;
	&-img {
	  text-align: left;
	}
	&-text {
	  display: flex;
	  margin-top: 10px;
      height: 60px;
      position: relative;
	  &-item {
		font-size: 13px;
		letter-spacing: 0.29px;
        text-align: center;
        width: calc(100% / 3);
		.step {
          font-family: 'Gilmer-Regular';
          font-size: 10px;
          width: 89px;
          height: 25px;
          line-height: 25px;
          border-radius: 49px;
          color: $white;
          text-align: center;
          margin-bottom: 5px;
          background-color: #7b9c91;
          margin: 0 auto;
        }
        &:nth-child(1) {
          .step {
              background-color: #ddd9d3;
          }
		}
		.title,
		.status {
		  font-family: 'Gilmer-Medium';
          img {
            margin-left: 5px;
          }
		}
		&:nth-child(2),
		&:nth-child(3) {
		  .title {
			text-decoration: underline;
			cursor: pointer;
		  }
		}
        &:nth-child(1) {
          position: absolute;
          left: -50px;

        }
		&:nth-child(2) {
          position: absolute;
          left: 175px;
        }
        &:nth-child(3) {
          position: absolute;
          right: -70px;
		}
	  }
	}
  }
}

.modal .modal-dialog {
  &.recommendation-prenatal-confirm-model.full-page {
    width: 100%;
    height: 100%;
    padding: 0;
    max-height: unset;

    .modal-header {
      position: relative;
      height: 84px;
      margin-bottom: -84px;
    }

    &.no-button {
      .modal-header {
        display: none;
      }
    }
  }
  &.recommendation-prenatal-confirm-model {
    .item-center {
      &.bg-phase1 {
        background: url('https://images.shaklee.com/meology/prenatal/prenatal-confirm-popup-bg-desktop.jpg')
                  right center no-repeat;
        background-size: cover;
      }
      &.bg-phase2 {
        background: url('https://images.shaklee.com/meology/prenatal/baby_desktop.jpg')
                  right top no-repeat;
        background-size: cover;
      }
      &>div {
        max-width: 980px;
        margin: 0 auto;
      }
    }
    .quiz-page .page  {
      padding: 75px 0 0 0;
      .transition .content {
        padding: 0% 10%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }
      .question {
        width: unset;
      }
      .questionnaire {
        overflow: hidden;
      }
      .prenatal-confirm {
        width: 80%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        .back-link,
        .content,
        .title {
          font-family: Gilmer-Regular;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
        }
        .title {
          height: 44px;
          font-size: 36px;
          color: #173e35;
        }
        .content {
          font-size: 24px;
          line-height: 1.5;
          color: #000;
          margin: 24px 0 48px;
        }
        .btn,
        .back-link {
          font-family: Gilmer-Medium;
          font-size: 20px;
          font-weight: 500;
          color: #000;
        }
        .back-link {
          height: 24px;
          margin-top: 24px;
          font-family: Gilmer-Medium;
          font-size: 20px;
          font-weight: 500;
          color: #000;
          span:last-of-type {
            cursor: pointer;
          }
        }
        .button-container {
          height: unset;
          margin: 0 auto;
        }
      }
    }
    .quiz-page {
      box-shadow: 1px 1px 6px 0 rgba(0,0,0,0.2);
      border: 1px solid rgba(0,0,0,.2);
    }
    .modal-content {
      box-shadow: unset;
      border: unset;

      .modal-header {
        width: 100%;
        padding: 1.5rem;
        .modal-title {
          cursor: pointer;
          margin-left: 30px;
        }
      }

      .modal-body {
        height: 100%;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    &.recommendation-prenatal-confirm-model.full-page {
      .modal-content {
        height: 100%;
      }
    }
    &.recommendation-prenatal-confirm-model {
      padding: 10px;
      .modal-content {
        .modal-header .modal-title {
          margin-left: 0;
        }
      }
      .item-center.bg-phase1 {
        background: url('https://images.shaklee.com/meology/prenatal/prenatal-confirm-popup-bg-mobile.jpg')
                    right top no-repeat;
        background-size: cover;
      }
      .item-center.bg-phase2 {
        background: url('https://images.shaklee.com/meology/prenatal/baby_mobile.jpg')
                    right top no-repeat;
        background-size: cover;
      }
      .quiz-page {
        min-height: 630px;
        .page {
          .answer input.text-field {
            margin-top: 30px;
          }
          .button-container {
            position: relative;
            z-index: unset;
          }
          .prenatal-confirm {
            width: 100%;
            padding: 0 30px 0 22px;
            align-items: flex-start;
            .button-container .question-buttons{
              padding: 0;
              margin: 0;
            }
            .title {
              height: unset;
              font-size: 22px;
              margin-top: 16px;
              font-family: 'Gilmer-Medium';
              line-height: 1.55;
              text-align: left;
            }
            .back-link {
              font-size: 16px;
              margin: 24px 0 0;
            }
            .content {
              font-size: 16px;
              margin: 24px 0 64px;
              line-height: 1.56;
              text-align: left;
            }
          }
        }
      }
      .prenatal_due_date_picker {
        .react-datepicker-popper {
          width: 75%;
        }
      }
    }
  }
  @media (min-width:769px) and (min-height:769px){
    &.recommendation-prenatal-confirm-model .item-center {
      display: flex;
      align-items: center;
      justify-content: center;
      .page {
        padding: 75px 0 100px 0;
      }
    }
  }
}
.prenatal-confirm-custom-model {
  padding-right: 0 !important;
}
/* end recommendation */

.product-toggle {
  position: absolute !important;
  right: 10px;
  top: 10px;
  width: 50px;
}

.sachet-image-wrap {
  display: flex;
  justify-content: center;
  padding: 0 0 18px 0;
}

.sachet-image-wrap-constant {
  display: flex;
  justify-content: center;
  position: relative;
  &-pic {
    height: 420px;
    width: 660px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .font-bold {
    position: absolute;
    bottom: 30px;
    margin-bottom: 0;
  }
}

.font-bold {
  font-size: 18px;
  letter-spacing: 0.34px;
  line-height: 21px;
  margin-bottom: 25px;
}

.sachet-image-wrap.proveIt {
  visibility: hidden;
}

.sachet-image {
  width: 260px;
  height: 300px;
  padding: 30px 30px 0 45px;
  background: url(https://images.shaklee.com/meology/images/Meology_Sachet_Blank_NoBG.png)
    no-repeat;
  background-size: 100% 100%;
  text-align: left;
  font-size: 0.625rem;
  color: #000000;
  font-family: 'SuisseIntl-Book', sans-serif;
  position: relative;
  .pills-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-height: 144px;
    overflow: hidden;
    li {
      font-family: 'SuisseIntl-Regular';
      font-size: 10px;
      color: #1e1e1e;
      letter-spacing: -0.1px;
      line-height: 13px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }
  &-name {
    font-size: 24px;
    color: #050505;
    letter-spacing: -0.3px;
    line-height: 40px;
    margin-top: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
  .pills-subtitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    font-size: 10px;
    font-weight: bold;
    color: #1e1e1e;
    padding: 10px 0 7px 0;
  }
  &-footer {
    position: absolute;
    bottom: 30px;
    .img-container {
      img {
        width: 90px;
      }
    }
  }
  &-index {
    text-align: right;
    font-family: 'SuisseIntl-Regular';
    font-size: 8px;
    color: #1e1e1e;
    letter-spacing: 0.12px;
    text-align: right;
  }
}

.sachet-image-origin {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sachet-image-generate {
  display: flex;
  justify-content: center;
  position: absolute;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.sachet-image-item {
  width: 200px;
  min-width: 200px;
  height: 233px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.has-two-sachet-image {
  .sachet-image-generate {
    .sachet-image-item:nth-child(1) {
      position: relative;
      z-index: 2;
      transform: translateX(40px);
    }
    .sachet-image-item:nth-child(2) {
      transform: translateX(-40px);
    }
  }
}

.text-caution {
  margin: 0;
  white-space: nowrap;
  transform: scale(0.9);
  transform-origin: 0 0;
}

.result-wrap1 {
  margin: 0 auto;
}

.result-wrap2 {
  padding: 55px 10% 116px 10%;
  background-color: $title;

  &.prenatal {
    background-color: $summary_prenatal_bg;

    .fixed-checkout-wrap {
      background-color: $summary_prenatal_bg;
    }

    .cart-btn-item button {
      color: $summary_prenatal_bg;
    }
    
    .incompleted-error {
      display: flex;
      justify-content: flex-end;
      font-family: Gilmer-Medium;
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      text-align: right;
    }
  }
}

.dagger-container {
  padding: 36px 10% 45px 10%;
  font-size: 13px;
  color: #767676;
  letter-spacing: 0.27px;
  line-height: 22px;
  text-align: left;
  &-provelt-title {
    font-size: 13px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #777676;
    padding: 0 0 20px;
    a {
      color: #777676;
      text-decoration: underline;
    }
  }
  &-title {
    border: 1px solid #979797;
    padding: 42px 30px;
  }
  &-content {
    padding: 35px 0 5px 0;
    &-item {
      padding-bottom: 30px;
      text-align: left;
    }
  }
  .cannada-disclaimers {
    border: 1px solid #979797;
    padding: 42px 30px;
    margin: 35px 0 0 0;
  }
}

.checkout-section {
  display: flex;
  justify-content: flex-end;
  margin-top: 46px;

  &.hasText {
    justify-content: space-between;
    align-items: flex-start;
  }

  &.is-proveit {
    justify-content: space-between;
  }

  &-text {
    font-size: 14px;
    color: $white;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  &-prenatal-text {
    position: relative;
    font-size: 16px;
    color: $white;
    padding: 0;
    margin: 0;
    text-align: left;
    line-height: 1.13;
    font-family: Gilmer-Regular;
    padding-left: 36px;
    u,a {
      cursor: pointer;
      color: $white;
      font-weight: bold;
      font-family: Gilmer-Bold;
    }
    &::before {
      position: absolute;
      content: '';
      display: block;
      height: 32px;
      width: 32px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url(https://images.shaklee.com/meology/prenatal/30days.svg) no-repeat;
      color: #fff;
      background-size: contain;
    }
  }
}

.price-content1 {
  font-size: 30px;
  display: flex;
  justify-content: space-between;
  color: $white;
  font-family: 'Shaklee-Verlag-Light';
}
.price-content1.supply {
  font-size: 20px;
  padding-bottom: 12px;
}

.top-wrap {
  padding: 38px 10%;
  background-color: $title;
  color: $white;
  .top-wrap-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 38px;
    width: 1140px;
    margin: 0 auto;

    &.no-toggle {
      justify-content: center;
    }

    .logo-regular {
      width: 130px;
    }
  }
  .top-wrap-headtext {
    display: flex;
    justify-content: center;
    font-size: 44px;
    line-height: 48px;
    margin-bottom: 17px;
    font-family: 'Shaklee-Verlag-Book', sans-serif;
  }
  .top-wrap-subtext {
    font-size: 24px;
    line-height: 31px;
  }
  .content1 {
    font-size: 30px;
    letter-spacing: 0;
    padding-top: 23px;
  }
  .content2 {
    font-size: 20px;
    letter-spacing: 0.3px;
    line-height: 26px;
    max-width: 720px;
    margin: 0 auto 10px auto;
  }
}

.tab1-wrap {
  height: 140px;
  position: relative;
}

.tab1 {
  list-style-type: none;
  margin: 0 -1rem;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 22px;
  padding: 40px 0;

  > li:nth-child(1) {
    .btn1 {
      border-radius: 5px 0 0 5px;
      width: 300px;
      height: 60px;
    }
  }
  > li:nth-child(2) {
    .btn1 {
      border-radius: 0 5px 5px 0;
      width: 300px;
      height: 60px;
    }
  }

  &.fixed {
    width: 100%;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  }
}

// btn
.btn1 {
  display: block;
  width: 150px;
  height: 40px;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  &.on {
    background-color: #fff;
    color: #026854;
  }
}

.tag-list {
  list-style-type: none;
  padding: 37px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  &-text {
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 21px;
    padding: 0 10px;
  }
}

.tag-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 112px;
  text-align: center;
  flex-shrink: 0;
  img {
    display: block;
    border-radius: 50%;
    width: 62px;
    height: 62px;
    margin-bottom: 10px;
  }
}

.product-info-switch {
  .custom-control-label {
    padding-top: 24px;
    font-size: 12px;
    user-select: none;
    color: #026854;
    letter-spacing: 0.28px;
    padding-top: 32px;
    width: 42px;
    &::before {
      left: 50%;
      transform: translateX(-50%);
      background: #85a99d;
      border: 1px solid #6c8a80;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
      border-radius: 9.66px;
      width: 48px;
      height: 21px;
    }
    &::after {
      transform: translateX(calc(-50% - 10px));
      width: 28px;
      height: 28px;
      border-radius: 50%;
      top: 0;
      left: 16px;
      background: #ffffff;
      border: 1px solid #026854;
    }
  }

  &.custom-switch {
    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        transform: translateX(calc(-50% + 0.975rem));
        border: 1px solid #026854;
        background: #026854;
      }
      &::before {
        background: #85a99d;
        border-color: #6c8a80;
      }
    }
    .custom-control-input:focus ~ .custom-control-label {
      border: 2px solid #000;
    }
    .custom-control-input:disabled ~ .custom-control-label,
    .custom-control-input[disabled] ~ .custom-control-label {
      color: #cee1dd;
      &::before {
        background-color: #e7eeec;
        border-color: #e7eeec;
        box-shadow: none;
      }
      &::after {
        background-color: #cee1dd !important;
        border-color: #cee1dd !important;
      }
    }
  }
}

.text-checkout {
  text-align: left;
  font-size: 14px;
  color: $white;
  margin-top: 10px;
  max-width: 562px;
  padding-right: 50px;
}

.btn-checkout {
  color: #026854;
  margin-top: 10px;
  height: 48px;
}

.app-block {
  margin-right: 0;
  margin-left: 0;
}

/* resultModal */

.modal-result {
  .modal-dialog {
    max-width: fit-content;
    margin: auto;
  }
  .modal-content {
    height: auto;
    padding: 1rem;
    border-radius: 0.4rem;
    max-width: 500px;
    .modal-body {
      padding: 2rem 0 0 0;
      .btn-container {
        padding: 10px 0;
        .modal-result-confirm {
          margin-top: 10px;
          &.link {
            &:hover {
              text-decoration: none;
            }
          }
        }
        .modal-result-confirm:nth-child(2) {
          border: 0;
        }
      }
    }
    .modal-header {
      width: 100%;
      padding: 1rem 0;
      position: relative;
      border-bottom: 1px solid #cccccc;
      &.no-title {
        border-bottom: none;
      }
    }
  }
  &-confirm,
  &-cancel {
    display: block;
    width: 100%;
    text-align: center;
    min-height: 50px;
    padding: 6px;
    background-color: transparent;
    color: $black;
    &:hover {
      color: $color_2;
    }
  }
  &-confirm {
    border: 1px solid #000;
  }
  &-cancel {
    margin-top: 10px;
    border: 0;
  }
  &.preview-preganant-phase-modal {
    .modal-content {
      max-width: 1280px;
      min-height: fit-content;
      background-color: #f0eeec;
      .modal-body {
        padding-left: 80px;
        padding-right: 80px;
        padding-bottom: 2rem;
      }
    }
    .title {
      font-size: 36px;
      text-align: center;
      margin-bottom: 10px;
      font-weight: normal;
    }
    .content {
      max-width: 610px;
      font-size: 24px;
      text-align: center;
      padding: 15px 0;
      margin: 0;
    }
    .modal-result-confirm {
      max-width: 360px;
      margin: 0 auto;
      border-width: 2px;
      border-radius: 3px;
    }

    //mobile style
    @media only screen and (max-width: 768px) {
      .modal-content {
        .modal-body {
          padding-left: 0;
          padding-right: 0;
          .title {
            font-size: 22px;
          }
          .content {
            width: 100%;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.modal-prenatal {
  .modal-dialog {
    width: 100vw;
    max-width: none;
    margin: 0 auto;
    justify-content: center;
  }
  .modal-content {
    max-width: 1000px;
    padding: 10% 0;
    position: relative;

    .modal-header {
      border: 0;
      position: static;

      .modal-title {
        max-width: 680px;
        margin: 0 auto;
        text-align: center;
        font-size: 36px;
        line-height: normal;
      }

      .close {
        position: absolute;
        right: 30px;
        top: 30px;
      }
    }

    .modal-body {
      padding: 0;
      width: 100%;

      &-content {
        max-width: 680px;
        text-align: center;
        font-size: 24px;
        padding: 30px 0;
        margin: 0 auto;
      }

      .btn-container {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        padding: 20px 0;

        .modal-result-confirm,
        .modal-result-confirm:nth-child(2) {
          border: 2px solid #000;
          font-size: 20px;
          border-radius: 3px;
          min-width: fit-content;
          max-width: 360px;
        }
      }
    }
  }
}

.restart-section {
  background: #f2ebe0;
  padding: 35px 0;
  text-align: center;
  border-radius: 2px;
  background-color: #fafbfb;

  .restart, .optin {
    font-size: 20px;
    color: #000000;
    letter-spacing: 0.3px;
    text-align: center;
    line-height: 26px;
    text-decoration: underline;
	padding: 0 50px;

	.icon {
	  display: inline-block;
	  height: 22px;
	  width: 22px;
	  margin: 0 5px 5px 0;
	}
  }
}

// Privacy Setting Modal

.optin {
  .modal-content {
	min-height: 90%;
	max-width: 95%;
	margin: 0 auto;

	.modal-header {
	  border-bottom: none !important;
	}

	.modal-title {
	  margin: 0 auto;
	  font-size: 36px;
	  padding-top: 85px;
	  font-family: Gilmer, sans-serif;
	}
  }
  .modal-content .modal-body {
	width: 60%;
	margin: 0 auto;
	padding-top: 45px !important;

	.animate-checkbox svg {
	  height: 32px;
	  width: 32px;
	}

	#email-capture-intro {
	  font-size: 24px;
	  line-height: 30px;
	  font-family: Gilmer, sans-serif;
	  font-weight: normal;
	  padding-bottom: 45px !important;

	  a {
		text-decoration: underline;
		color: #000000;
	  }
	}

	.btn-container {
	  max-width: 35%;
	  margin: 0 auto;

	  .modal-result-confirm {
		border-width: 2px;
	  }
	}
  }
}
//addon style
.add-on-section {
  padding: 0 10% 20px 10%;
  background-color: $reco_content_bg;
  &-text {
    font-size: 20px;
    padding: 32px 0;
    color: #000000;
    text-align: left;
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -15px;
    &-item {
      width: 33.333333%;
      padding: 0 15px;
      margin-bottom: 30px;
      .product-item {
        .card-body {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: column;
        }
        .list {
          .product-attributes-list {
            margin: 0;
            padding: 0 0 20px 0;
            display: flex;
            flex-wrap: wrap;
            &-item {
              padding-right: 8px;
              padding-bottom: 8px;
              img {
                width: 40px;
                height: 40px;
                display: block;
              }
            }
          }
        }
        &-content {
          margin-bottom: 10px;
        }
        &-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          color: #000000;
          letter-spacing: 0.1px;
          line-height: 15.29px;
          width: 100%;
          &.hide {
            justify-content: right;
          }
          &-link {
            color: #000000;
            padding: 0;
            text-decoration: underline;
            font-weight: bold;
          }
        }
        background: #ffffff;
        box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border-radius: 4px;
        height: 100%;
        .product-info-switch
          .custom-control-input:checked
          ~ .custom-control-label::after {
          transform: translateX(calc(-50% + 16px));
          border: 1px solid #026854;
          background: #026854;
        }
        .custom-control-label {
          font-size: 11px;
          color: #026854;
          letter-spacing: 0.28px;
          padding-top: 32px;
          width: 42px;
          white-space: nowrap;
        }
        .custom-control-label::before {
          background: #85a99d;
          border: 1px solid #6c8a80;
          box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
          border-radius: 9.66px;
          width: 48px;
          height: 21px;
        }
        .product-info-switch .custom-control-label::after {
          transform: translateX(calc(-50% - 10px));
        }
        .custom-control-label::after {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          top: 0;
          left: 16px;
          background: #ffffff;
          border: 1px solid #026854;
        }
        .card-body {
          padding: 20px 25px;
          .card-title {
            font-size: 24px;
            color: #000000;
            line-height: 1.3;
            text-align: left;
          }
          .card-text {
            font-size: 18px;
            color: #3e3e3e;
            letter-spacing: 0.27px;
            line-height: 1.278;
            text-align: left;
          }
          .special-tag {
            text-align: left;
            font-size: 16px;
            color: #7f7f7f;
            line-height: 22px;
          }
          .reason-list {
            padding-inline-start: 20px;
            &-item {
              text-align: left;
              font-size: 18px;
              color: #3e3e3e;
              letter-spacing: 1.278;
              line-height: 36px;
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
}

//result select style
.result-Select {
  @keyframes showOpinion {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  @keyframes unShowOpinion {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  display: inline-block;
  width: 100%;
  position: relative;
  .iconUp {
    animation: loading-1 0.5s;
    transform: rotate(180deg);
  }
  .iconDown {
    animation: unShowOpinion 0.5s;
    transform: rotate(0deg);
  }
  .select-wrap {
    min-width: 270px;
    border: 1px solid #4a4a4a;
    border-radius: 2px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .select-icon {
      transition: 0.3s;
      float: right;
    }
    .select-text{
      text-align: left;
    }
    .placeholder-text {
      color: rgba(74,74,74,0.5);
    }
  }
  .option-wrap {
    width: 100%;
    text-align: left;
    box-shadow: 0 0 5px #333;
    z-index: 1000;
    border-radius: 5px;
    .option-item {
      background-color: #fff;
      padding: 8px 12px;
      width: 100%;
      cursor: pointer;
      &.selected {
        background-color: #fbe6d0;
      }
    }
  }
  &.prenatal {
    .option-wrap,
    .select-wrap {
      line-height: 1.13;
      letter-spacing: 0.35px;
      color: #4a4a4a;
    }
  }
}

//pill style
.pill-section {
  background-color: $reco_content_bg;
  padding: 20px 10%;
  &-title {
    background-color: #ffffff;
  }
  &-title2 {
    font-size: 20px;
    padding: 32px 0;
    color: #000000;
    text-align: left;
  }
  .pill-container {
    &:first-child {
      .pill-line {
        display: none;
      }
    }
    .pill-line {
      height: 2px;
      background-color: #fff;
      margin: 20px 0;
    }
  }

  .pill-info {
    background: #ffffff;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    position: relative;
    padding: 30px;
    .product-info-switch {
      position: absolute;
      top: 24px;
      right: 24px;
    }
    .product-info-switch
      .custom-control-input:checked
      ~ .custom-control-label::after {
      transform: translateX(calc(-50% + 16px));
      border: 1px solid #026854;
      background: #026854;
    }
    .custom-control-label {
      font-size: 11px;
      color: #026854;
      letter-spacing: 0.28px;
      padding-top: 32px;
      width: 42px;
    }
    .custom-control-label::before {
      background: #85a99d;
      border: 1px solid #6c8a80;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
      border-radius: 9.66px;
      width: 48px;
      height: 21px;
    }
    .custom-control-label::after {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      top: 0;
      left: 16px;
      background: #ffffff;
      border: 1px solid #026854;
    }
    .product-info {
      display: flex;
      &-left {
        width: 50%;
        text-align: left;
        &-name {
          font-size: 26px;
          color: #000000;
          line-height: 31px;
          margin-top: 7px;
        }
        &-desc {
          margin-top: 12px;
          font-size: 18px;
          color: #3e3e3e;
          letter-spacing: 0.5px;
          line-height: 30px;
          width: 80%;
        }
        &-why {
          .product-attributes-list {
            margin: 0;
            padding: 16px 0 20px 0;
            display: flex;
            flex-wrap: wrap;
            &-item {
              padding-right: 8px;
              img {
                width: 40px;
                height: 40px;
                display: block;
              }
            }
          }
        }
        &-backorder {
          font-family: 'Verlag-Book';
          font-size: 14px;
          color: #da3939;
          letter-spacing: 0.21px;
        }
        &-picture {
          width: 120px;
        }
        .product-info-image.prenatal-essential-1 {
          .product-info-left-picture {
            max-width: 306px;
            width: unset;
            min-width: 120px;
          }
        }

      }
      &-right {
        text-align: left;
        width: 40%;
        position: relative;
        &-why {
          font-size: 24px;
          color: #000000;
          line-height: 31px;
          margin-bottom: 15px;
        }
        .reason-list {
          padding-inline-start: 20px;
          &-item {
            text-align: left;
            font-size: 18px;
            color: #3e3e3e;
            letter-spacing: 0.27px;
            line-height: 36px;
            padding-left: 10px;
          }
        }
      }
      &-note {
        font-size: 14px;
        color: #e05151;
        margin-top: 5px;
      }
    }
    .product-footer {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &-left {
        .learn-more {
          text-decoration: underline !important;
          font-size: 16px;
          font-family: 'Shaklee-Verlag-Bold', sans-serif;
          color: #000000;
          letter-spacing: 0.3px;
          line-height: 21px;
          padding: 0px;
        }
      }
      &-right {
        font-size: 16px;
        color: #000000;
        letter-spacing: 0.3px;
        text-align: right;
        line-height: 21px;
      }
    }
  }
}

.pill-section.plus {
  background-color: #f2ebe0;
  padding-bottom: 40px;
}

.more-section {
  padding: 20px 10%;
  background-color: $reco_content_bg;
  min-width: fit-content;
  @at-root .btn-more {
    $more: &;
    background-color: #f2ebe0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    padding: 35px;
    &:hover {
      #{$more}-img {
        transform: translateX(-10px);
      }
    }
    &-text {
      p, h2 {
        margin: 0;
        padding: 0;
      }
      h2 {
        font-size: 24px;
        font-weight: bold;
      }
      p {
        font-size: 20px;
      }
    }
    &-img {
      display: block;
      width: 24px;
      height: 20px;
      transition: transform 0.3s ease;
    }
  }
}

.subtotal {
  border-top: 1px solid $white;
  margin-top: 10px;
  padding-top: 10px;
  font-family: 'Shaklee-Verlag-Light';
}

.customize-text {
  font-size: 16px;
  color: #7f7f7f;
  text-align: left;
}

.fixed-checkout-wrap {
  background-color: $green2;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  transform: translateY(100%);
  will-change: transform;
  transition: transform 0.5s ease;
  padding: 14px 10%;

  .fixed-checkout .cart-btn button {
    font-family: Gilmer-Bold;
    font-size: 18px;
    min-width: 302px;
  }

  &.fixed {
    transform: translateY(0);
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3);
    z-index: 1001;
    .fixed-checkout {
      .cart-btn {
        &-item {
          .cart-btn-tips {
            .dropdown-menu {
              bottom: 0;
              top: initial;
            }
          }
        }
      }
    }
  }
}

.fixed-checkout {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  align-items: center;

  &-subtotal {
    color: #fff;
    font-size: 20px;
    font-family: 'Gilmer-Medium', sans-serif;
  }
}

.prove-it {
  background-color: $reco_content_bg;
  padding: 0 10% 20px;

  &::before {
    display: block;
    content: '';
    border-top: 2px solid #fff;
    padding-bottom: 20px;
  }

  &-title {
    font-size: 20px;
    padding: 20px 0;
    text-align: center;
    color: #ffffff;
    font-family: 'Gilmer-Regular';
    font-weight: 500;
    border-radius: 4px;
    background-color: #026854;
  }

  &-box {
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: #f1eeeb;
    border-radius: 4px;
    padding: 50px;
    position: relative;
    .prove-it-list {
      .prove-it-list-item1,
      .prove-it-list-item3,
      .prove-it-list-item2 {
        .prove-it-list-select-wrap.mobile {
          display: none;
        }
      }
    }


    .prove-it-logo {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 136px;
      height: 136px;
      background-color: #509188;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-price {
        font-family: 'SchnyderS-Bold', sans-serif;
        font-size: 34px;
        color: $white;
      }
      &-sub_price {
        font-family: 'Gilmer-Bold', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: $white;
      }
    }
  }

  &-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #026854;
      color: #fff;
      padding: 10px 15px;
      height: 36px;
      border-radius: 18px;
    }

    &-button {
      border-radius: 3px;
      color: #006954;
      text-transform: uppercase;
      font-family: 'Gilmer-Bold', sans-serif;
      width: 565px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      margin: 30px auto 10px;
      border: solid 1px #006954;
      background-color: initial;
    }

    &-button.isPdpFlow {
      display: none;
    }
  }

  &-header {
    &-title {
      margin-bottom: 10px;
      line-height: 1.5;
      font-family: 'SchnyderS-Bold', sans-serif;
      font-size: 32px;
    }

    &-price {
      font-family: 'Shaklee-Verlag-Bold', sans-serif;
      font-size: 18px;
      color: #026854;
      margin-bottom: 10px;
      em {
        font-style: normal;
      }
    }

    &-content {
      font-size: 16px;
      font-family: 'Gilmer-Regular', sans-serif;
      margin-bottom: 30px;
      color: #000;
    }

    .product-attributes-list {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      &-item {
        margin: 0 4px;
        img {
          display: block;
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  &-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-width: 500px;
    margin: 0 auto;

    > li {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-item1,
    &-item3,
    &-item2 {
      width: calc(100% / 3);
    }

    &-item1 {
      .prove-it-list-step {
        background-color: $reco_content_bg;
        color: #dbd5cf;
      }
    }

    &-link {
      text-decoration: underline;
      cursor: pointer;
    }

    &-add {
      position: relative;
      padding: 40px 0;
      span {
        font-family: 'Shaklee-Verlag-Light', sans-serif;
        font-size: 60px;
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #000;

        &::before {
          content: '';
          display: block;
          height: 0;
          width: 0;
          margin-bottom: -0.16em;
        }
      }
    }

    &-select-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-select {
      margin-top: 15px;
      font-size: 14px;
      color: #000;

      .select-wrap {
        border-color: #000;
      }
      .select-text {
        padding-right: 20px;
      }
    }

    &-step {
      border-radius: 70px;
      width: 127px;
      height: 35px;
      line-height: 35px;
      font-size: 14px;
      font-family: "Gilmer-Bold";
      background-color: #7b9c91;
      color: #fff;
      text-align: center;
    }
    &-pic {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      height: 250px;

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-title {
      font-size: 20px;
      font-family: 'Gilmer-Bold', sans-serif;
      margin-top: 10px;
      color: #2e2e2e;
    }

    &-content {
      color: #000;
      font-size: 16px;
      font-family: 'Gilmer-Regular', sans-serif;
      padding: 0 20%;
      margin: 0;
      span {
        display: block;
        font-family: 'Gilmer-Bold', sans-serif;
      }
    }

    &-content.mobile {
      display: none;
    }

    &-link {
      margin-bottom: 40px;
      font-family: 'Gilmer-Regular', sans-serif;
      color: #000;
      font-size: 16px;
      text-decoration: underline;
    }

    &-link.mobile {
      display: none;
    }
  }
}

// prove it banner
.prove-it-banner {
  background-color: rgba(208, 201, 186, 0.3);
  color: #000;
  padding: 10px 10%;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;


  * {
    margin: 0;
    padding: 0;
  }

  button {
    appearance: none;
    border: 0 none;
    background-color: transparent;
    outline: none;
    color: #2e2e2e;
    font-family: 'Gilmer-Regular';
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      border-radius: 0.25rem;
    }
  }

  &-content {
    text-align: left;
    font-size: 18px;
    p {
      font-size: 20px;
    }
  }

  &-title {
    font-size: 29px;
    font-family: 'SchnyderS-Bold', sans-serif;
  }

  &-sub_title {
    font-family: 'Gilmer-Medium', sans-serif;
    font-size: 20px;
    color: #133e35;
  }

  p {
    color: #2e2e2e;
    font-family: 'Gilmer-Regular', sans-serif;
  }

  &-image {
    list-style-type: none;
    display: flex;
    margin-left: 40px;
    img {
      height: 160px;
    }

    &-title {
      font-size: 24px;
      padding: 5px 0;
    }
    &-add {
      font-size: 34px;
      font-family: 'Shaklee-Verlag-Light', sans-serif;
      line-height: 30px;
      display: flex;
      align-items: center;
    }
    &-content {
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 20px;

      img {
        display: block;
        max-height: 100%;
      }
    }
  }

  &-button {
    margin-left: 5px;
    border-bottom: 1px solid #000 !important;
    line-height: normal;
  }
}

//prenatal rsw
.recommendations-prenatal-rsw {
  padding: 20px 10%;
  &-banner {
    background: url('https://images.shaklee.com/wellness/BG2.png');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 150px 10px 0;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.15);
    &-image {
      img {
        width: 150px;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      &-title {
          font-family: 'SchnyderS-Bold';
          font-size: 24px;
          line-height: 1.25;
          letter-spacing: 0.12px;
          color: #ffffff;
      }
      &-sub_title {
        width: 264px;
        border-radius: 1px;
        background-color: #fff;
        font-family: 'SchnyderS-Bold';
        font-size: 22px;
        line-height: 2;
        letter-spacing: 0.07px;
        text-align: center;
        color: #173e35;
        margin-top: 8px;
        span {
          font-family: 'SchnyderS-Demi';
          font-size: 14px;
        }
      }
      &-button {
        font-family: 'Gilmer-Regular';
        font-size: 12px;
        line-height: 2.33;
        letter-spacing: 0.06px;
        text-align: center;
        color: #fff;
        span {
          text-decoration: underline;
        }
        cursor: pointer;
      }
    }
  }
  @keyframes showOpinion {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes unShowOpinion {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  &-body {
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: #F1F5EE;
    border-radius: 4px 4px 0 0;
    padding: 25px;
    position: relative;
    &-box {
      .header {
        &-title {
          margin-bottom: 10px;
          line-height: 1.5;
          font-family: 'SchnyderS-Bold', sans-serif;
          font-size: 36px;
        }

        &-content {
          font-size: 16px;
          font-family: 'Gilmer-Regular', sans-serif;
          margin-bottom: 30px;
          color: #000;
          strong {
            display: block;
          }
        }
      }
      .tip,.success-tip {
        line-height: 19px;
        margin-top: 20px;
        font-family: 'Gilmer-Medium';
        font-size: 16px;
        color: #133e35;
      }
      .success-tip {
        img {
          margin-right: 10px;
        }
      }
      .rsw-toggle-button {
        border-radius: 2px;
        border: solid 1px #173e35;
        background-color: #173e35;
        color: #fff;
        font-family: 'Gilmer-Medium';
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        width: 550px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px auto 0 auto;
      }
      .rsw-toggle-button.noFinishSelect {
        opacity: 0.3;
        background-color: #173e35;
      }
      .rsw-toggle-button.isSelectedProveIt {
        background-color: initial;
        color: #173e35;
      }
      .list {
        display: flex;
        .list-item1,.list-item2,.list-item3 {
          width: calc(100% / 3 - 20px);
          margin: 0 10px;
          border-radius: 3px;
          box-shadow: 0 2px 4px 0 rgba(185, 185, 185, 0.5);
          background-color: #fff;
          position: relative;
          padding: 15px 15px 60px 15px;
          .hook {
            position: absolute;
            right: 20px;
          }
          .list-title {
            height: 52px;
            font-family: 'Gilmer-Medium';
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.53px;
            color: #000;
            text-align: left;
          }
          .list-step {
            position: absolute;
            top: 0;
            left: 0;
            width: 89px;
            height: 36px;
            line-height: 36px;
            opacity: 0.8;
            border-radius: 3px;
            background-color: #7b9c91;
            font-family: 'Gilmer-Bold';
            font-size: 18px;
            letter-spacing: 0.4px;
            color: #fff;
          }
          .list-pic {
            margin-top: 60px;
            img {
              height: 210px;
            }
          }
          .product-footer {
            display: flex;
            position: absolute;
            bottom: 0;
            height: 60px;
            line-height: 60px;
            .learn-more {
              text-decoration: underline !important;
              font-family: 'Shaklee-Verlag-Bold', sans-serif;
              font-size: 16px;
              color: #000000;
              letter-spacing: 0.3px;
              line-height: 21px;
              padding: 0px;
            }
          }
        }
        .list-item1 {
          .list-sub_title {
            font-family: 'Gilmer-Medium';
            font-size: 16px;
            letter-spacing: 0.24px;
            color: #2e2e2e;
            text-align: left;
          }
          .list-tip {
            text-align: left;
            font-family: 'Gilmer-Regular';
            font-size: 14px;
            letter-spacing: 0.21px;
            color: #2e2e2e;
          }
        }
      }
    }
  }
  &-body.open {
    animation: showOpinion 1s;
    opacity: 1;
    display: block;
  }
  &-body.close {
    animation: unShowOpinion 1s;
    opacity: 0;
    display: none;
  }
  &-footer {
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.15);
    height: 35px;
    width: 100%;
    line-height: 35px;
    border-radius: 0 0 4px 4px;
    background-color: #59716c;
    font-family: 'Gilmer-Regular';
    font-size: 12px;
    line-height: 2.33;
    letter-spacing: 0.06px;
    text-align: center;
    color: #fff;
    span {
      text-decoration: underline;
    }
    cursor: pointer;
  }
} 

// Recommendation top section of Meology Kids
.kids-banner {
  background-color: #e7f4db;
  padding: 30px 20px;

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 38px;
    width: 1140px;
    margin: 0 auto;

    &-logo {
      width: 167px;
    }
  }

  &-title {
    font-size: 36px;
  }

  &-subtitle,
  &-price {
    font-size: 20px;
  }

  &-edit {
    all: unset;
    text-decoration: underline;
    font-size: 20px;
    margin-bottom: 30px;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      border-radius: 0.25rem;
    }
  }
}
.kids {
  font-family: 'Gilmer-Regular', sans-serif;
  &.recommendations {
    .section-title {
      margin: 0;
      padding: 15px 0 22px 10%;
      background: #f7fbf4;
      font-family: 'Gilmer-Regular', sans-serif;
    }
    .pill-section,
    .pill-line,
    .add-on-section {
      background-color: #f7fbf4;
    }
    .pill-section .pill-info {
      .product-info-left-name {
        margin-right: 30px;
      }

      .product-info-left-name,
      .product-info-right-why {
        font-family: 'Gilmer-Medium', sans-serif;
        font-size: 20px;
        letter-spacing: 0.56px;
      }

      .product-info-right {
        .reason-list-item {
          font-family: 'Gilmer-Light', sans-serif;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 0.5px;

          &::marker {
            font-size: 8px;
          }
        }
      }
      .product-footer-left .learn-more {
        font-family: 'Gilmer-Bold', sans-serif;
        font-size: 13px;
        letter-spacing: 0.34px;
        line-height: 21px;
        text-decoration: none !important;
        border-bottom: 1.5px solid #000;
        border-radius: 0;
      }
      .product-footer-right {
        color: #4a4a4a;
      }
    }
    .add-on-section-content-item {
      width: auto;
      flex: 1;
      .product-item .card-body {
        .card-title {
          font-family: 'Gilmer-Medium', sans-serif;
          font-size: 22px;
          line-height: 31px;
        }
        .card-text {
          font-size: 18px;
          letter-spacing: 0.27px;
          line-height: 23px;
        }
        .list + .card-title {
          font-size: 20px;
        }
        .reason-list-item {
          &::marker {
            font-size: 8px;
          }
          line-height: 23px;
        }
      }
      .product-item-footer-link {
        font-family: 'Gilmer-Bold', sans-serif;
        font-size: 13px;
        letter-spacing: 0.34px;
        line-height: 21px;
        text-decoration: none !important;
        border-bottom: 1.5px solid #000;
        border-radius: 0;
      }
    }
  }
}
.meology-toggle {
  $root: &;
  all: unset;
  list-style-type: none;
  display: flex;
  width: 208px;
  height: 51px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: $shaklee_green;
  color: $shaklee_green;
  font-family: Gilmer-Medium;

  &.light {
    border-color: #fff;
    color: #fff;

    li {
      &:nth-child(2) {
        border-color: #fff;
      }

      &.active {
        background-color: #fff;
        color: $shaklee_green;
      }
    }
  }

  li {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 14px;
    padding: 0 5px;
    user-select: none;

    &:nth-child(2) {
      border-left: 1px solid $shaklee_green;
      border-right: 1px solid $shaklee_green;
    }

    &.active {
      background-color: $shaklee_green;
      color: #fff;
    }

    &:hover {
      #{$root}-tips {
        display: block;
      }
    }
  }

  &-tips {
    display: none;
    background-color: #1e1e1e;
    color: #fff;
    font-size: 16px;
    text-align: center;
    border-radius: 3px;
    padding: 9px 10px;
    white-space: nowrap;
    position: absolute;
    z-index: 1;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #1e1e1e transparent;
    }
  }
}

// Recommendation top section of Meology prenatal
.confirm-container {
  padding: 20px 10%;
  background-color: $reco_content_bg;

  .banner-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
  }
  .flex-1 {
    flex: 1;
  }
  .flex-0 {
    flex: 0 0 auto;
  }
  .confirm-banner,.sub-confirm-banner {
    border-radius: 4px;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #eef3ea;
    opacity: 0.9;
    font-family: 'Gilmer-Medium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #2e2e2e;
    padding: 16px 24px;
    &-icon {
      margin-right: 20px ;
    }
    span {
      text-align: left;
      a,u {
        color: #2e2e2e;
        cursor: pointer;
      }
    }
    &-btn {
      min-width: 215px;
      padding: 11px 20px;
      margin-left: 30px;
      cursor: pointer;
      border-radius: 2px;
      background-color: #2a4a43;
      font-size: 16px;
      letter-spacing: 0.24px;
      line-height: 1.63;
      font-weight: 500;
      text-align: center;
      color: $white;
      white-space: nowrap;
      border: none;
    }
  }
  .sub-confirm-banner {
    margin-top: 16px;
  }
}
@media only screen and (max-width: 990px) {
  .confirm-banner  {
    .banner-flex {
      flex-direction: column;
    }
    .confirm-banner-btn {
      align-self: flex-start;
      margin: 16px 0 0 0;
    }
  }

  .confirm-banner,.sub-confirm-banner {
    &-icon {
      align-self: flex-start;
      margin-top: 3px;
    }
  }
}
.prenatal-welcome {
  padding: 16px 0px;
  margin: 0;
  box-shadow: 0 2px 4px 0 rgba(203, 201, 201, 0.5);
  background-color: #eef3ea;
  font-family: SchnyderS-Demi, sans-serif;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 1px;
  color: #2e2e2e;
  text-align: left;
  position: relative;

  &.warning {
    background-color: #F7EFD1
  }

  & > .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    box-sizing: border-box;

  	div {
      padding: 0;
    }
  }

  .learn-more {
	  color: #2e2e2e;
	  text-decoration: underline;
	  padding-left: 5px;
    cursor: pointer;
    border: none;
    background: transparent;
	}
	&.hide{
	  display: none;
	}
  .close {
	  background: url('https://images.shaklee.com/meology/prenatal/icon_close.svg') 50% no-repeat;
	  font-size: 0;
	  border: none;
	  width: 26px;
	  height: 26px;
	  opacity: 0.9;
	}
}
.prenatal-banner {
  padding: 30px 20px;
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 30px;
    max-width: 1200px;
    box-sizing: border-box;

    &-logo {
      width: 167px;
    }
  }
  &-bottom {
    .prenatal-banner-title {
      font-family: 'Gilmer-Regular', sans-serif;
      font-size: 44px;
      margin-top: 32px;
    }
    .prenatal-banner-subtitle {
      font-family: 'Gilmer-Regular', sans-serif;
      font-size: 20px;
      max-width: 760px;
      margin: 0 auto;
      padding: 10px 0;
    }
    .prenatal-banner-label {
      font-family: 'Gilmer-Medium', sans-serif;
      font-size: 20px;
      margin-top: 16px;
    }
	.sachet-powder img {
	  height: 292px;
	}
  }

  &.is-preparing {
    background-color: #FFFAF5;
  }
  &.is-pregnant {
    background-color: #FFF4E9;
  }
  &.is-postnatal {
    background-color: #EFEDDE;
  }
}

// Add to existing loyalty order successfully modal
.loyalty-order-modal {
  .modal-content {
    padding: 30px;
  }

  &-content {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0 0 2rem;
    }
  }
}

// Prenatal Addon
.prenatal-addon {
  padding-bottom: 48px;
  background-color: #fff;
  &-title {
    font-size: 20px;
    padding: 32px 0;
    text-align: left;
  }

  & &-section {
    display: grid;
    grid-template-columns:  repeat(auto-fit, minmax(306px, 1fr));
    gap: 15px;

    > div {
      flex: 1;
      min-width: 306px;
    }
    .pill-container {
      width: auto;
      .pill-line {
        display: none;
      }
      .pill-info {
        height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .product-info {
          display: block;
          &-left,
          &-right {
            width: 100%;
          }
        }
      }
    }
    .add-on-section-content-item {
      padding: 0;
      width: auto;
      margin-bottom: 0;
      .product-item {
        padding: 20px;
        .card-body {
          padding: 0;
        }
      }
    }
    .product-info-image {
      height: 200px;
      line-height: 200px;
      text-align: center;
      .item-img {
        height: 200px;
        width: auto;
        margin-top: 0;
      }
    }
    .product-info-image.prenatal-option {
      max-height: 455px;
      height: unset;
      line-height: 100%;
      margin: -20px -20px 30px -20px;
      background-color: #f7f7f7;
      border-radius: 4px;

      .item-img,
      .product-info-left-picture {
        max-height: 455px;
        width: 100%;
        height: unset;
        max-width: 455px;
        border-radius: 4px;
      }
    }
    .product-footer,
    .product-item-footer {
      margin-top: 0;
    }
    .product-item-button {
      margin-top: 15px;
      button {
        width: 100%;
        height: 54px;
        background-color: #173e35;
        border: none;

        &.added {
          background-color: white;
          color: #173e35;
          border: 1px solid #173e35;
        }
      }
    }

    .product-footer {
      &-left {
        .learn-more {
          line-height: inherit;
        }
      }

      &-right {
        line-height: inherit;
      }
    }

    .product-info-left-name {
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 0.75rem;
      line-height: 1.3;
    }

    .product-info-left-desc {
      width: 100%;
      line-height: 1.278;
      margin-top: 0;
      margin-bottom: 1rem;
    }

    .product-info-left-why {
      .product-attributes-list {
        padding-top: 0;

        &-item {
          padding-bottom: 8px;
        }
      }
    }
  }

  .checkbox-button {
    margin-top: 15px;
  }
}

// Prenatal Step
.prenatal-title {
  font-size: 36px;
  margin-bottom: 30px;
  font-family: Gilmer-Regular;
}
.prenatal-step {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  font-family: 'Gilmer-Medium';

  &-wrap {
    display: flex;
    justify-content: center;
  }

  &-item {
    min-width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    position: relative;
    padding-bottom: 70px;
    padding-left: 5px;
    padding-right: 5px;
    color: rgba(46, 46, 46, .7);

    @media screen and (max-width: $xxs-width) {
      padding-left: 0;
      padding-right: 0;
    }

    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: #6A6866;
      box-shadow: 0 0 1px 3px rgba(102, 102, 102, .32);
      position: absolute;
      bottom: 23px;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #8B8785;
      position: absolute;
      bottom: 35px;
    }

    &:first-child {
      &::before {
        width: 50%;
        right: 0;
      }
    }

    &:last-child {
      &::before {
        width: 50%;
        left: 0;
      }
    }
  }


  &-title {
    margin: 0;
    padding: 3px 0;
    font-size: 1.25rem;

    @media screen and (max-width: $xxs-width) {
      white-space: initial !important;
    }
  }

  &-current-text,
  &-active-text {
    color: $shaklee_green;
    font-size: 12px;
    font-family: 'Gilmer-Medium';
    margin: 0 0 10px 0;
  }

  &-current-text {
    display: flex;
    &::before {
      @include glyphicons;
      content: '\e006';
      margin-right: 5px;
    }
  }

  .active {
    color: $shaklee_green;
    &::after {
      width: 70px;
      height: 70px;
      background-color: #47655B;
      box-shadow: 0 0 1px 3px rgba(42, 74, 67, .32);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      bottom: 0;
    }
  }

  &-trying {
    &.active {
      &::after {
        background-image: url('https://images.shaklee.com/meology/prenatal/icon_trying.svg');
      }
    }
  }

  &-pregnant {
    &.active {
      &::after {
        background-image: url('https://images.shaklee.com/meology/prenatal/icon_pregnant.svg');
      }
    }
  }

  &-postpartum {
    &.active {
      &::after {
        background-image: url('https://images.shaklee.com/meology/prenatal/icon_postpartum.svg');
      }
    }
  }
  &-meology {
    &.active {
      &::after {
        background-image: url('https://images.shaklee.com/meology/prenatal/mkt_prenatal_b_5.svg');
        background-size: contain;
      }
    }
  }
}

.preview-postnatal-head {
  max-width: 850px;
  margin: 0 auto;

  &-text {
    font-family: 'Gilmer-Regular';
    text-align: center;
    font-size: 36px;
    line-height: 1.2;
    color: #2E2E2E;
    padding: 36px 0;
  }

  &-button {
    font-family: 'Gilmer-Medium';
    font-size: 20px;
    width: 360px;
    height: 60px;
    border: 1px solid #000;
    border-radius: 3px;
    background-color: #EFEDDE;
    color: #000;

    &:hover {
      filter: brightness(110%);
    }

    &:active {
      filter: brightness(90%);
    }
  }
}

// No Meology Result
.no-meology {
  $root: &;

  &-regular {
    color: #fff;
    background: url("https://images.shaklee.com/meology/images/landing/desktop/image_module_hero_1200.jpg") 50% 50% no-repeat #006a52;
    min-height: 900px;

    #{$root}-logo {
      width: 260px;
      margin-top: 120px;
    }

    #{$root}-title {
      margin-top: 20px;
    }

    #{$root}-intro {
      max-width: 490px;
      margin-top: 15px;
    }
  }

  &-prenatal {
    #{$root}-logo {
      width: 192px;
      margin: -36px auto 0;
    }

    #{$root}-subtitle {
      font-family: 'Gilmer-Medium';
      font-size: 18px;
      text-align: center;
      color: #2e2e2e;
      padding: 26px 0;
    }

    #{$root}-content {
      font-family: 'Gilmer-Regular';
      max-width: 1200px;
      background-color: #F5F3F2;
      padding: 27px;
      text-align: center;
      color: #333;
    }

    #{$root}-title {
      font-size: 26px;
    }

    #{$root}-intro {
      line-height: 1.44;
      &-inner {
        max-width: 530px;
        margin: 0 auto;
      }
    }

    #{$root}-brand {
      background: linear-gradient(180deg, #FAF5F3 33.3333vw, transparent 0);
      margin-top: 6.9444vw;
      display: flex;
      align-items: center;
      flex-direction: column;

      &-title {
        font-family: 'SchnyderS-Demi';
        font-size: 6.25vw;
        color: #333;
        transform: translateY(-5vw);
        line-height: 1;
        margin: 0;
      }

      &-box {
        background-image: url(https://images.shaklee.com/meology/prenatal/no_meology_prenatal.jpg);
        background-repeat: no-repeat;
        width: 83.3333vw;
        height: 40.625vw;
        padding-right: 51.3889vw;
        padding-left: 6.9444vw;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &-intro {
        text-align: center;
        font-size: 16px;
        color: #2e2e2e;
      }

      &-logo {
        width: 12.8472vw;
        height: 12.8472vw;
        background-color: #D8DBCA;
        border-radius: 50%;
        background-image: url(https://images.shaklee.com/meology/prenatal/prenatal_logo2.svg);
        background-size: 60% auto;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }

    &.no-toggle {
      #{$root}-logo {
        margin: 10px auto 0;
      }
    }
  }

  &-kids {
    #{$root}-logo {
      width: 200px;
      margin: 85px auto 0;
    }

    #{$root}-title {
      text-align: center;
      margin-top: 20px;
    }

    #{$root}-intro {
      margin-top: 30px;
      padding-bottom: 50px;
      text-align: center;
    }

    #{$root}-video-wrap {
      background: linear-gradient(180deg,#d6efbe 570px,#fff 0,#fff);
    }

    #{$root}-video-inner {
      padding-top: 100px;
    }
  }

  &-main,
  &-video-inner {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  &-logo {
    display: block;
  }

  &-title {
    font-size: 26px;
  }

  &-intro {
    font-family: "Gilmer-Regular";
    font-size: 18px;
  }
}

// toast
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.toast-wrap {
  font-family: 'Shaklee-Verlag-Book', sans-serif;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 80%;
  max-width: 830px;

  &-upload {
    width: fit-content;
    .toast-list {
      &-item {
        border-color: $black;
        background-color: $black;
        color: $white;
        font-size: 14px;
        display: flex;
        align-items: center;

        &-icon {
          display: block;
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }

        &-close {
          display: none;
        }
      }
    }
  }

  @at-root .toast-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &-item {
      margin-bottom: 10px;
      background-color: $white;
      border: $red solid 1px;
      border-radius: 5px;
      color: $red;
      padding: 10px 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      position: relative;

      &.fadeOutUp {
        animation: fadeOutUp 0.2s ease-out;
      }

      &.fadeInUp {
        animation: fadeInUp 0.2s ease-in;
      }

      &-icon {
        display: none;
      }

      &-close {
        display: block;
        width: 10px;
        height: 10px;
        padding: 20px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        color: #000;
        background-image: url('https://images.shaklee.com/meology/icons/mobile/close_button.png');
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .kids-banner {
    &-top {
      width: 100%;
      padding: 0 10px;
    }
  }
  .prenatal-banner {
    &-top {
      padding: 0 10px;
    }
  }
  .checkout-section {
    .checkout-section-prenatal-text {
      &::before {
        top: 0;
        transform: none;
      }
      &+ .cart-btn {
       flex-direction: column;
       .cart-btn-item {
         width: 312px;
         margin-bottom: 16px;
         button {
           width: 100%;
         }
       }
     }

    }
  }
}
//mobile style
@media only screen and (max-width: 768px) {
  /* step recommendation */
  .recommendations-step-provelt {
    .recommendations-step-provelt-container {
      padding: 14px;
      .recommendations-step-provelt-info {
        padding: 20px;
        .product-info {
          display: flex;
          flex-direction: column;
          &-left {
            width: 100%;
            &-picture {
              max-height: initial;
            }
            img {
              width: 100%;
            }
          }
          &-right {
            width: 100%;
            .prove-it-list-title {
              font-size: 22px;
              text-align: left;
            }
          }
        }
        .product-footer {
          margin-top: 25px;
        }
      }
    }
  }
  .recommendations-step-banner {
    flex-direction: column;
    padding: 20px;
    &-title {
      font-size: 20px;
      text-align: left;
    }
    &-title.hidden {
      display: none;
    }
    &-container {
      margin-left: 0px;
      margin-top: 10px;
      width: fit-content;
      &-img {
        text-align: left;
        margin-left: 20px;
        img {
          width: 280px;
        }
      }
      &-text {
        display: flex;
        margin-top: 10px;
        &-item {
          .step {
            width: 65px;
            height: 19px;
            line-height: 19px;
          }
          font-size: 10px;
          &:nth-child(1) {
            left: -15px;
          }
          &:nth-child(2) {
            left: 120px;
          }
          &:nth-child(3) {
            right: -45px;
          }
        }
      }
    }
  }
  .modal-result {
    .modal-dialog {
      padding: 20px;
    }
  }

  .modal-prenatal {
    .modal-content {
      padding: 10%;

      .modal-header {
        .modal-title {
          font-size: 20px;
        }

        .close {
          right: 10px;
          top: 10px;
        }
      }

      .modal-body {
          &-content {
          font-size: 14px;
          padding: 10px 0;
        }

        .btn-container {
          .modal-result-confirm,
          .modal-result-confirm:nth-child(2) {
            font-size: 14px;
            border: 1px solid #000;
          }
        }
      }
    }
  }

  .confirm-container {
    padding: 14px;
    .confirm-banner,.sub-confirm-banner {
      padding: 16px;
      font-size: 16px;
      line-height: 1.44;
      font-family: Gilmer-Regular;
      &-icon {
        width: 20px;
        margin:  3px 16px 0 0;
        align-self: flex-start;
        img {
          opacity: 0.95;
          mix-blend-mode: multiply;
        }
      }
      &-btn {
        padding: 5px 20px;
      }
    }
    .sub-confirm-banner {
      .confirm-banner-icon {
        img {
          width: 20px;
        }
      }
    }
    .confirm-banner {
      &-icon {
        .img-1 {
          width: 30px;
        }
        .img-2 {
          width: 20px;
        }
        .img-3 {
          width: 14px;
        }
      }
    }
  }

  .prenatal-banner {
    &-top {
      width: 100%;
    }
    &-bottom {
      padding: 0 10px;
      .prenatal-banner-title {
        font-size: 24px;
      }
      .prenatal-banner-subtitle {
        font-size: 18px;
      }
      .prenatal-banner-label {
        font-size: 18px;
      }
    }
  }
  .sachet-image {
    width: 191px;
    height: 224px;
    padding: 20px 15px 0 26px;
    background-size: contain;
    .pills-list {
      max-height: 144px;
      li {
        font-size: 8px;
        line-height: 10px;
      }
    }
    &-name {
      margin-top: 8px;
      line-height: 20px;
      font-size: 18px;
      letter-spacing: -0.19px;
    }
    &-footer {
      bottom: 24px;
      .img-container {
        img {
          width: 51px;
        }
      }
    }
    &-index {
      text-align: right;
      font-size: 7px;
      letter-spacing: 0.1px;
      padding-right: 2px;
      height: 10px;
    }
    .pills-subtitle {
      font-size: 8px;
      letter-spacing: 0;
      padding: 10px 0 6px 0;
      font-family: $font_suisse_medium;
    }
  }
  .sachet-image-wrap {
    padding: 0 0 10px 0;
  }
  .sachet-image-wrap-constant {
    &-pic {
      width: 100%;
      height: 210px;
    }
    .font-bold {
      bottom: 10px;
    }
  }
  .result-wrap2 {
    padding: 23px 14px;
    .price-content1 {
      font-size: 20px;
      text-align: left;
      &.supply {
        font-size: 16px;
        line-height: 21px;
        padding-bottom: 10px;
      }
    }

    &.prenatal {
      .incompleted-error {
        font-size: 16px;
        text-align: left;
      }
    }
  }
  .dagger-container {
    padding: 30px 14px 0 14px;
    &-title,
    .cannada-disclaimers {
      padding: 25px 20px;
    }
  }
  .checkout-section {
    flex-direction: column;

    & > &-prenatal-text {
      text-align: left;
      padding-bottom: 26px;

      & + .cart-btn .cart-btn-item {
        width: 100%;
        margin-left: 0;
      }
    }
    &-text {
      text-align: center;
      padding-top: 10px;
    }

  }
  .restart-section {
    padding: 26px 0;
    .restart, .optin {
      font-size: 14px;
      padding: 0;
    }

    .restart {
      margin-right: 32px;
    }
  }

  .add-on-section {
    padding: 0 14px 20px 14px;
    &-text {
      font-size: 18px;
      line-height: 26px;
      padding: 19px 0;
    }
    &-content {
      display: block;
      margin: 0;
      &-item {
        padding: 20px 0;
        margin: 0;
        &:not(:last-child) {
          padding-right: 0;
          border-bottom: 2px solid #ffffff;
        }
        width: 100%;
        .product-item {
          margin: 0;
          .list {
            .product-attributes-list {
              margin: 0;
              padding: 0 0 20px 0;
            }
          }
          .item-img {
            width: 100%;
          }
          .product-info-switch {
            position: absolute;
            right: 20px;
            top: 20px;
          }
          .custom-control-label {
            padding-top: 24px;
          }
          .custom-control-label::before {
            border-radius: 7px;
            width: 34px;
            height: 14px;
          }
          .custom-control-label::after {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            top: 0;
            left: 16px;
            background: #ffffff;
            border: 1px solid #026854;
          }
          .product-info-switch
            .custom-control-input:checked
            ~ .custom-control-label::after {
            transform: translateX(calc(-50% + 14px));
          }
          .product-info-switch .custom-control-label::after {
            transform: translateX(calc(-50% - 6px));
          }
          .card-body {
            .product-item-content {
              margin-bottom: 30px;
            }
            .product-item-footer {
              font-size: 14px;
            }
            padding: 25px;
            .card-title {
              font-size: 18px;
              line-height: 26px;
            }
            .card-text {
              font-size: 16px;
              line-height: 24px;
            }
            .reason-list {
              &-item {
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
  .top-wrap {
    padding: 22px 14px;
    .top-wrap-logo {
      padding-bottom: 14px;
      width: 100%;
      .logo-regular {
        width: 90px;
      }
    }
    .top-wrap-headtext {
      flex-direction: column;
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 14px;
      word-break: break-word;
    }
    .content1 {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 30px;
    }
    .content2 {
      font-size: 16px;
      letter-spacing: 0.3px;
      line-height: 20px;
      max-width: 100%;
    }
  }
  .font-bold {
    font-size: 14px;
  }
  .tab1-wrap {
    height: 78px;
  }
  .tab1 {
    padding: 20px 0;
    font-size: 14px;
    > li {
      .btn1 {
        width: 158px !important;
        height: 38px !important;
      }
    }
  }
  .tag-list {
    padding: 26px 0 0 0;
    justify-content: flex-start;
    .tag-item {
      padding-bottom: 20px;
    }
    &-text {
      font-size: 14px;
      letter-spacing: 0.3px;
      line-height: 21px;
    }
  }
  .pill-section {
    padding: 14px;
    &-title2 {
      font-size: 16px;
      padding: 26px 0;
    }
    .pill-info {
      padding: 20px;
      .product-info-switch {
        top: 25px;
        right: 25px;
      }
      .custom-control-label {
        padding-top: 24px;
      }
      .custom-control-label::before {
        border-radius: 7px;
        width: 34px;
        height: 14px;
      }
      .custom-control-label::after {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: 0;
        left: 16px;
        background: #ffffff;
        border: 1px solid #026854;
      }
      .product-info-switch
        .custom-control-input:checked
        ~ .custom-control-label::after {
        transform: translateX(calc(-50% + 14px));
      }
      .product-info-switch .custom-control-label::after {
        transform: translateX(calc(-50% - 6px));
      }
      .product-info {
        flex-direction: column;
        &-left {
          width: 100%;
          &-name {
            font-size: 22px;
            line-height: 30px;
          }
          &-desc {
            font-size: 16px;
            width: 100%;
          }
          &-why {
            .product-attributes-list {
              margin: 0;
              padding: 16px 0;
            }
          }
          // &-picture {
          //     width: 80px;
          // }
        }
        &-right {
          width: 100%;
          margin-top: 15px;
          &-why {
            font-size: 18px;
            line-height: 30px;
          }
          .reason-list {
            &-item {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
      .product-footer {
        margin-top: 30px;
        &-left {
          .learn-more {
            font-size: 14px;
          }
        }
        &-right {
          font-size: 14px;
          padding-top: 4px;
        }
      }
    }
  }
  .text-checkout {
    font-size: 12px;
    text-align: center;
    padding-right: 0;
    margin-top: 0;
    max-width: 100%;
  }
  .more-section {
    padding: 18px 14px;
  }
  .top-wrap {
    .top-wrap-subtext {
      font-size: 18px !important;
      line-height: 22px;
      margin-bottom: 20px;
      div {
        display: inline;
        padding: 0 2px 0;
      }
    }
  }
  .recommendations {
    .section-title {
      margin: 0;
      padding: 32px 20px 15px;
      font-size: 18px;
      color: #000000;
      letter-spacing: 0.3px;
      line-height: 26px;
    }
    &.is-preview-postnatal {
      height: auto;
    }
  }
  .btn-more {
    padding: 20px;
    &-text {
      p {
        font-size: 16px !important;
      }
    }
  }
  .fixed-checkout-wrap {
    padding: 14px;
    .fixed-checkout {
      flex-direction: row;
      align-items: center;

      &-subtotal {
        font-size: 18px;
      }

      &.two-btns {
        flex-direction: column;
        align-items: flex-start;
        .cart-btn {
          display: grid;
          grid-column-gap: 16px;
          grid-template-columns: repeat(2, 1fr);
          width: 100%;
          margin-top: 8px;
        }
        .cart-btn-item {
          margin: 0;

          button {
            width: 100%;
          }
        }
      }

      .auto,
      .cart-btn {
        width: auto;
        padding: 0;

        button {
          margin: 0;
          font-size: 13px;
          min-width: 160px;
        }
      }
    }
  }
  .fixed-checkout {
    flex-direction: column;
    align-items: start;
    text-align: left;
  }
  .prove-it {
    padding: 14px;

    &-title {
      font-size: 18px;
      line-height: 26px;
      padding-bottom: 19px;
    }

    &-box {
      .prove-it-logo {
        width: 95px;
        height: 95px;
        right: 20px;
        left: initial;
        &-price {
          font-size: 22px;
        }
      }
      .prove-it-header {
        text-align: left;
      }
      .prove-it-list {
        .prove-it-list-item1,
        .prove-it-list-item3,
        .prove-it-list-item2 {
          width: 100%;
          .prove-it-list-select-wrap.mobile {
            display: block;
          }
        }
        .prove-it-list-item1 {
          margin-bottom: 50px;
        }
      }
      .prove-it-list.desktop {
        display: none;
      }
    }

    &-toggle {
      &-button {
        width: 100%;
        height: 52px;
        font-size: 16px;
      }
    }

    &-header {
      &-title {
        padding-top: 15px;
        width: 200px;
      }
      &-price,
      &-content {
        font-size: 16px;
      }
    }

    &-list {
      > li {
        &:nth-of-type(3) {
          min-width: auto;
        }
      }
      &-content {
        padding: 0;
      }
    }
  }
  .prove-it-banner {
    flex-direction: column;
    padding: 14px;
    &-title {
      font-size: 27px;
      text-align: left;
    }
    &-sub_title {
      font-size: 16px;
      text-align: left;
    }
    p {
      font-size: 16px;
      text-align: left;
    }
    &-content {
      padding: 10px 0;
      font-size: 16px;
      margin: 0;
    }
    &-image {
      padding: 10px 0;
      width: 100%;
      img {
        width: 100%;
        height: initial;
      }
      justify-content: center;
      &-content {
        margin: 0;
        height: 75px;
      }
    }
  }
  .kids-banner {
    padding: 22px 14px;

    &-top {
      padding-bottom: 22px;
      width: 100%;
      padding: 0;
      margin-bottom: 26px;

      &-logo {
        width: 110px;
      }
    }

    &-title {
      font-size: 28px;
      margin-bottom: 15px;
    }

    &-subtitle {
      font-size: 18px;
      margin-bottom: 25px;
    }

    &-price {
      font-size: 16px;
      margin-top: 10px;
    }

    &-edit {
      margin: 20px 0;
      font-size: 16px;
    }
  }
  .prenatal-banner {
    &-top {
      padding-bottom: 22px;
      width: 100%;
      padding: 0;
      margin-bottom: 26px;
      margin-left: -4px;

      &-logo {
        width: 110px;
      }
    }
  }
  .prenatal-welcome {
    & > .content {
      padding: 0 14px;
    }
  }
  .result-Select {
    font-size: 12px;
  }
  .kids {
    &.recommendations {
      .pill-section {
        .pill-container {
          .pill-info {
            .product-footer {
              &-left {
                .learn-more {
                  font-size: 12px;
                }
              }

              &-right {
                font-size: 12px;
              }
            }

            .product-info {
              &-left {
                &-picture {
                  width: 100px;
                }

                &-name {
                  margin: 0;
                  font-size: 22px;
                }
              }

              &-right {
                &-why {
                  font-size: 18px;
                }

                .reason-list {
                  &-item {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }

      .section-title {
        padding: 14px 14px 0 14px;
      }

      .add-on-section {
        &-text {
          padding-bottom: 0;
        }

        &-content {
          &-item {
            .product-item {
              .card-body {
                .product-item-content {
                  .card-text {
                    font-size: 16px;
                  }

                  .card-title {
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .meology-toggle {
    width: 160px;
    height: 38px;

    &-tips.right {
      left: 0;
      transform: unset;
      &::after {
        left: 24px;
      }
    }
  }
  // Add to existing loyalty order successfully modal
  .loyalty-order-modal {
    .modal-content {
      padding: 1rem;
    }
  }

  .prenatal-addon {
    &-section {
      display: block;

      .pill-container,
      .add-on-section-content-item {
        width: 100%;
        padding-right: 0;
        margin-bottom: 15px;
      }

      .add-on-section-content-item {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .prenatal-title {
    font-size: 26px;
  }

  .prenatal-step {
    width: 100%;
    &-title {
      font-size: 14px;
      text-align: center;
      white-space: nowrap;
    }

    &-item {
      min-width: auto;
      width: auto;
      min-width: auto;
      flex: 1;
      padding-bottom: 50px;

      &::after {
        width: 17px;
        height: 17px;
        bottom: 16px;
      }

      &::before {
        bottom: 25px;
      }
    }

    &-current-text,
    &-active-text {
      font-size: 8px;
    }

    .active {
      &::after {
        width: 50px;
        height: 50px;
        background-size: 35px 35px;
      }
    }
  }

  .preview-postnatal-head {

    &-text {
      font-size: 16px;
      padding: 15px 0;
      line-height: 1.4;
    }

    &-button {
      font-size: 16px;
      width: 100%;
      height: 50px;
    }
  }

  .no-meology {
    $root: &;
    &-regular {
      background-image: url("https://images.shaklee.com/meology/Me.png");
      background-position: 100% 0;
      background-size: 100% auto;
      min-height: 660px;

      #{$root}-logo {
        width: 200px;
        margin-top: 300px;
        margin-left: auto;
        margin-right: auto;
      }

      #{$root}-title {
        font-size: 20px;
        text-align: center;
      }

      #{$root}-intro {
        max-width: 100%;
        font-size: 14px;
        text-align: center;
      }
    }

    &-kids {
      #{$root}-video-wrap {
        background: linear-gradient(180deg,#d6efbe 140px,#fff 0,#fff);
      }

      #{$root}-logo {
        width: 150px;
        margin-top: 20px;
      }

      #{$root}-title {
        font-size: 20px;
        margin-top: 0;
      }

      #{$root}-intro {
        font-size: 14px;
        margin-top: 10px;
      }

      #{$root}-video-inner {
        padding-top: 30px;
      }
    }

    &-prenatal {
      #{$root}-logo {
        margin: 30px auto 0;
      }

      #{$root}-main {
        padding: 15px;
      }

      #{$root}-title {
        font-size: 20px;
      }

      #{$root}-brand {
        &-box {
          padding: 54vw 0 0 0;
          display: initial;
          position: relative;
          background-image: url(https://images.shaklee.com/meology/prenatal/no_meology_prenatal_mobile.jpg);
          width: 100vw;
          height: auto;
          background-size: contain;
          background-color: #fff8f2;
          position: relative;
        }

        &-logo {
          position: absolute;
          width: 30vw;
          height: 30vw;
          left: 5vw;
          top: 13vw;
        }

        &-title {
          padding: 0 15px;
        }

        &-intro {
          margin: 0;
          padding: 15px;
          text-align: left;
          font-family: 'Gilmer-Regular';
        }
      }
    }
  }

  .optin {
	.modal-content {
	  min-height: 70%;
	  .modal-title {
		font-size: 32px;
		padding-top: 50px;
	  }
	  .modal-body {
		width: 90%;
		#email-capture-intro {
		  font-size: 16px;
		  line-height: 24px;
		}
	  }
	}
  }

  .prove-it-list-content.mobile {
    display: block;
  }

  .prove-it-list-link.mobile {
    display: block;
  }

  //prenatal rsw
  .recommendations-prenatal-rsw {
    padding: 0px;
    &-banner {
      background: url('https://images.shaklee.com/wellness/BG2_mobile.png');
      background-size: cover;
      padding: 10px;
      &-content {
        margin-left: 0;
        &-title {
          font-size: 22px;
        }
        &-sub_title {
          width: initial;
        }
      }
    }
    &-body {
      padding: 14px;
      &-box {
        .rsw-toggle-button {
          width: 100%;
        }
        .list {
          display: block;
          .list-item1,.list-item2,.list-item3 {
            width: 100%;
            margin: 20px 0;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 359px) {
  .sachet-image-origin {
    display: block !important;
  }
}
.modal.backorder {
  &.has-two-modal {
    + .modal-backdrop {
      z-index: 1050;
    }
  }

  .modal-dialog {
    padding: 20px;
    .modal-content {
      height: inherit;
      max-height: calc(100vh - 20px);
      @media only screen and (min-width: 1200px) {
        max-width: 800px;
      }
    }
  }
  .modal-header {
    padding-top: 30px;
    border-bottom: 0;

    .close {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .modal-title {
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 36px;
    color: #000000;
    font-family: 'SuisseIntl-Regular';
    text-align: center;

    @media screen and (max-width: 1200px) {
      font-size: 24px;
    }
  }
  .modal-body {
    font-family: 'SuisseIntl-Light';
    font-size: 18px;
    color: #4a4a4a;
    text-align: center;
    line-height: 26px;
    overflow-y: auto;
    padding: 30px 60px 0;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (max-width: 1200px) {
      padding: 10px;
    }

    p {
      flex: 1;
    }

    .modal-result-confirm {
      @media only screen and (min-width: 1200px) {
        width: 314px;
        margin: 0 auto;
      }
    }
  }
}

@media (min-width: 1024px) {
  .prove-it {
    &-list {
      min-width: 100%;
      display: flex;
      align-items: flex-start;

      &-add {
        padding: 60px 20px;
      }

      &-select {
        font-size: 16px;
      }
    }

    &-header {
      &-content {
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

}


.font-gilmer-light {
  font-family: Gilmer-Light !important;
}
.font-gilmer-medium {
  font-family: Gilmer-Medium !important;
}
.font-gilmer-regular {
  font-family: Gilmer-Regular !important;
}
.font-gilmer-bold {
  font-family: Gilmer-Bold !important;
}
